<template>
    <div class="w-full max-h-screen bg-background flex flex-col relative">
        <page-header title="Work Order Details" backTo="tools-workorder-index">
            <button
                type="button"
                class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                v-if="workOrder && workOrder.order_state == 'NEW'"
                @click="updateWorkOrderStatus('cancelled')">
                Update to Cancelled
            </button>
            <button
                type="button"
                class="ml-4 flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                v-if="workOrder && workOrder.order_state == 'NEW'"
                @click="updateWorkOrderStatus('complete')">
                Update to Completed
            </button>
        </page-header>

        <main class="p-6 bg-background flex-1 overflow-y-scroll">
            <div v-if="workOrder">
                <div class="w-full bg-white p-5 rounded shadow-md">
                    <div class="flex flex-wrap -my-3 -mx-5 mt-2">
                        <div class="w-full flex flex-col">
                            <div class="flex flex-wrap">

                                <!-- Site number -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Site Number</label>
                                    <input type="text" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.site_number" />
                                </div>
                                <!-- /Site number -->

                                <!-- Vehicle Registration -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Registration #.</label>
                                    <input type="text" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.vehicle_registration" />
                                </div>
                                <!-- /Vehicle Registration -->

                                <!-- Vehicle Year -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Registration Year</label>
                                    <input type="number" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.vehicle_year" />
                                </div>
                                <!-- /Vehicle Year -->

                                <!-- Vehicle Make -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Make</label>
                                    <input type="text" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.vehicle_make" />
                                </div>
                                <!-- /Vehicle Make -->

                                <!-- Vehicle Model -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Model</label>
                                    <input type="text" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.vehicle_model" />
                                </div>
                                <!-- /Vehicle Model -->

                                <!-- Fuel type -->
                                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Fuel Type</label>  
                                    <input type="text" class="w-full bge-input bge-input-spacing rounded uppercase" disabled v-model="workOrder.vehicle_fuel_type" />                               
                                </div>
                                <!-- Fuel type -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full bg-white p-5 rounded shadow-md mt-6">
                    <h3 class="text-2xl mb-4">DVSA Data</h3>
                    <pre v-if="workOrder.dvsa_data">{{ workOrder.dvsa_data }}</pre>

                    <div v-if="workOrder.dvsa_error">
                        <div class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900">
                            <p>Couldn't load work order data from DVSA: {{ workOrder.dvsa_error }}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div v-show="viewState == 'Loading'">
                <spinner class="mx-auto my-auto" :color="'text-black'" :size="8" />
            </div>
            
            <div v-if="error">
                <div class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900">
                    <p>Couldn't load work order details {{ error ? `(${error})` : `` }}</p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader.vue'
    import Notification from '@/components/Notification.vue'
    import Spinner from '@/components/Spinner.vue'
    import axios from 'axios'

    export default {
        props: {
            orderId: String
        },
        components: {
            PageHeader,
            Notification,
            Spinner
        },
        data() {
            return {
                viewState: 'Idle',
                error: null,
                workOrder: null
            }
        },
        async mounted() {
            await this.loadOrder()
        },
        methods: {
            async updateWorkOrderStatus(newStatus) {
                console.log(newStatus)
                try {
                    let updateUrl = `/v2/api/tools/dvsaworkorders/${this.orderId}/${newStatus}`
                    await axios.put(updateUrl)
                    await this.loadOrder()
                    
                    this.$breadstick.notify(({ h, onClose }) => {
                        return h(
                            Notification,  
                            { props: { title: "Order Updated", close: onClose } },
                            `Work order updated to ${newStatus.toUpperCase()}`)
                    }, { position: "top-right" })
                } catch (error) {
                    console.error(error)
                    this.$breadstick.notify(({ h, onClose }) => {
                        return h(
                            Notification,  
                            { props: { type: "danger", title: "Error", close: onClose } },
                            `Update work order failed: ${error.message}`);
                    }, { position: "top-right" })
                }
            },
            async loadOrder() {
                this.viewState = 'Loading'
                try {
                    let orderUrl = `/v2/api/tools/dvsaworkorders/${this.orderId}`
                    let result = await axios.get(orderUrl)

                    this.workOrder = result.data
                    this.viewState = 'Idle'

                } catch (error) {
                    this.viewState = 'Error'
                    this.error = error
                }
            }
        }
    }
</script>